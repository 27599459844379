@-webkit-keyframes anim-1 {
    100% {
      transform: translate3d(76vw, 0vh, 0) scale(0.5) rotate(144deg) rotateY(133deg) rotateX(135deg) rotateZ(24deg);
    }
  }
  
  @keyframes anim-1 {
    100% {
      transform: translate3d(76vw, 0vh, 0) scale(0.5) rotate(144deg) rotateY(133deg) rotateX(135deg) rotateZ(24deg);
    }
  }
  
  @-webkit-keyframes anim-2 {
    100% {
      transform: translate3d(64vw, 0vh, 0) scale(0.5) rotate(63deg) rotateY(168deg) rotateX(35deg) rotateZ(56deg);
    }
  }
  
  @keyframes anim-2 {
    100% {
      transform: translate3d(64vw, 0vh, 0) scale(0.5) rotate(63deg) rotateY(168deg) rotateX(35deg) rotateZ(56deg);
    }
  }
  
  @-webkit-keyframes anim-3 {
    100% {
      transform: translate3d(64vw, 0vh, 0) scale(0.5) rotate(71deg) rotateY(105deg) rotateX(133deg) rotateZ(135deg);
    }
  }
  
  @keyframes anim-3 {
    100% {
      transform: translate3d(64vw, 0vh, 0) scale(0.5) rotate(71deg) rotateY(105deg) rotateX(133deg) rotateZ(135deg);
    }
  }
  
  @-webkit-keyframes anim-4 {
    100% {
      transform: translate3d(47vw, 0vh, 0) scale(0.5) rotate(112deg) rotateY(69deg) rotateX(34deg) rotateZ(163deg);
    }
  }
  
  @keyframes anim-4 {
    100% {
      transform: translate3d(47vw, 0vh, 0) scale(0.5) rotate(112deg) rotateY(69deg) rotateX(34deg) rotateZ(163deg);
    }
  }
  
  @-webkit-keyframes anim-5 {
    100% {
      transform: translate3d(80vw, 0vh, 0) scale(0.5) rotate(84deg) rotateY(157deg) rotateX(30deg) rotateZ(77deg);
    }
  }
  
  @keyframes anim-5 {
    100% {
      transform: translate3d(80vw, 0vh, 0) scale(0.5) rotate(84deg) rotateY(157deg) rotateX(30deg) rotateZ(77deg);
    }
  }
  
  @-webkit-keyframes anim-6 {
    100% {
      transform: translate3d(69vw, 0vh, 0) scale(0.5) rotate(128deg) rotateY(109deg) rotateX(87deg) rotateZ(151deg);
    }
  }
  
  @keyframes anim-6 {
    100% {
      transform: translate3d(69vw, 0vh, 0) scale(0.5) rotate(128deg) rotateY(109deg) rotateX(87deg) rotateZ(151deg);
    }
  }
  
  @-webkit-keyframes anim-7 {
    100% {
      transform: translate3d(2vw, 0vh, 0) scale(0.5) rotate(112deg) rotateY(170deg) rotateX(109deg) rotateZ(137deg);
    }
  }
  
  @keyframes anim-7 {
    100% {
      transform: translate3d(2vw, 0vh, 0) scale(0.5) rotate(112deg) rotateY(170deg) rotateX(109deg) rotateZ(137deg);
    }
  }
  
  @-webkit-keyframes anim-8 {
    100% {
      transform: translate3d(51vw, 0vh, 0) scale(0.5) rotate(130deg) rotateY(130deg) rotateX(119deg) rotateZ(78deg);
    }
  }
  
  @keyframes anim-8 {
    100% {
      transform: translate3d(51vw, 0vh, 0) scale(0.5) rotate(130deg) rotateY(130deg) rotateX(119deg) rotateZ(78deg);
    }
  }
  
  @-webkit-keyframes anim-9 {
    100% {
      transform: translate3d(40vw, 0vh, 0) scale(0.5) rotate(59deg) rotateY(14deg) rotateX(67deg) rotateZ(2deg);
    }
  }
  
  @keyframes anim-9 {
    100% {
      transform: translate3d(40vw, 0vh, 0) scale(0.5) rotate(59deg) rotateY(14deg) rotateX(67deg) rotateZ(2deg);
    }
  }
  
  @-webkit-keyframes anim-10 {
    100% {
      transform: translate3d(72vw, 0vh, 0) scale(0.5) rotate(176deg) rotateY(106deg) rotateX(96deg) rotateZ(95deg);
    }
  }
  
  @keyframes anim-10 {
    100% {
      transform: translate3d(72vw, 0vh, 0) scale(0.5) rotate(176deg) rotateY(106deg) rotateX(96deg) rotateZ(95deg);
    }
  }
  
  @-webkit-keyframes anim-11 {
    100% {
      transform: translate3d(69vw, 0vh, 0) scale(0.5) rotate(118deg) rotateY(85deg) rotateX(45deg) rotateZ(50deg);
    }
  }
  
  @keyframes anim-11 {
    100% {
      transform: translate3d(69vw, 0vh, 0) scale(0.5) rotate(118deg) rotateY(85deg) rotateX(45deg) rotateZ(50deg);
    }
  }
  @-webkit-keyframes anim-12 {
    100% {
      transform: translate3d(76vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(177deg) rotateX(22deg) rotateZ(46deg);
    }
  }
  
  @keyframes anim-12 {
    100% {
      transform: translate3d(76vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(177deg) rotateX(22deg) rotateZ(46deg);
    }
  }
  
  @-webkit-keyframes anim-13 {
    100% {
      transform: translate3d(32vw, 0vh, 0) scale(0.5) rotate(117deg) rotateY(106deg) rotateX(76deg) rotateZ(7deg);
    }
  }
  
  @keyframes anim-13 {
    100% {
      transform: translate3d(32vw, 0vh, 0) scale(0.5) rotate(117deg) rotateY(106deg) rotateX(76deg) rotateZ(7deg);
    }
  }
  
  @-webkit-keyframes anim-14 {
    100% {
      transform: translate3d(86vw, 0vh, 0) scale(0.5) rotate(73deg) rotateY(52deg) rotateX(45deg) rotateZ(149deg);
    }
  }
  
  @keyframes anim-14 {
    100% {
      transform: translate3d(86vw, 0vh, 0) scale(0.5) rotate(73deg) rotateY(52deg) rotateX(45deg) rotateZ(149deg);
    }
  }
  
  @-webkit-keyframes anim-15 {
    100% {
      transform: translate3d(89vw, 0vh, 0) scale(0.5) rotate(180deg) rotateY(138deg) rotateX(43deg) rotateZ(51deg);
    }
  }
  
  @keyframes anim-15 {
    100% {
      transform: translate3d(89vw, 0vh, 0) scale(0.5) rotate(180deg) rotateY(138deg) rotateX(43deg) rotateZ(51deg);
    }
  }
  
  @-webkit-keyframes anim-16 {
    100% {
      transform: translate3d(38vw, 0vh, 0) scale(0.5) rotate(7deg) rotateY(160deg) rotateX(77deg) rotateZ(149deg);
    }
  }
  
  @keyframes anim-16 {
    100% {
      transform: translate3d(38vw, 0vh, 0) scale(0.5) rotate(7deg) rotateY(160deg) rotateX(77deg) rotateZ(149deg);
    }
  }
  
  @-webkit-keyframes anim-17 {
    100% {
      transform: translate3d(34vw, 0vh, 0) scale(0.5) rotate(150deg) rotateY(7deg) rotateX(160deg) rotateZ(54deg);
    }
  }
  
  @keyframes anim-17 {
    100% {
      transform: translate3d(34vw, 0vh, 0) scale(0.5) rotate(150deg) rotateY(7deg) rotateX(160deg) rotateZ(54deg);
    }
  }
  
  @-webkit-keyframes anim-18 {
    100% {
      transform: translate3d(49vw, 0vh, 0) scale(0.5) rotate(5deg) rotateY(157deg) rotateX(65deg) rotateZ(43deg);
    }
  }
  
  @keyframes anim-18 {
    100% {
      transform: translate3d(49vw, 0vh, 0) scale(0.5) rotate(5deg) rotateY(157deg) rotateX(65deg) rotateZ(43deg);
    }
  }
  
  @-webkit-keyframes anim-19 {
    100% {
      transform: translate3d(34vw, 0vh, 0) scale(0.5) rotate(104deg) rotateY(145deg) rotateX(147deg) rotateZ(145deg);
    }
  }
  
  @keyframes anim-19 {
    100% {
      transform: translate3d(34vw, 0vh, 0) scale(0.5) rotate(104deg) rotateY(145deg) rotateX(147deg) rotateZ(145deg);
    }
  }
  
  @-webkit-keyframes anim-20 {
    100% {
      transform: translate3d(70vw, 0vh, 0) scale(0.5) rotate(174deg) rotateY(153deg) rotateX(136deg) rotateZ(33deg);
    }
  }
  
  @keyframes anim-20 {
    100% {
      transform: translate3d(70vw, 0vh, 0) scale(0.5) rotate(174deg) rotateY(153deg) rotateX(136deg) rotateZ(33deg);
    }
  }
  
  @-webkit-keyframes anim-21 {
    100% {
      transform: translate3d(86vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(83deg) rotateX(78deg) rotateZ(77deg);
    }
  }
  
  @keyframes anim-21 {
    100% {
      transform: translate3d(86vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(83deg) rotateX(78deg) rotateZ(77deg);
    }
  }
  
  @-webkit-keyframes anim-22 {
    100% {
      transform: translate3d(10vw, 0vh, 0) scale(0.5) rotate(110deg) rotateY(61deg) rotateX(37deg) rotateZ(3deg);
    }
  }
  
  @keyframes anim-22 {
    100% {
      transform: translate3d(10vw, 0vh, 0) scale(0.5) rotate(110deg) rotateY(61deg) rotateX(37deg) rotateZ(3deg);
    }
  }
  
  @-webkit-keyframes anim-23 {
    100% {
      transform: translate3d(77vw, 0vh, 0) scale(0.5) rotate(120deg) rotateY(132deg) rotateX(7deg) rotateZ(156deg);
    }
  }
  
  @keyframes anim-23 {
    100% {
      transform: translate3d(77vw, 0vh, 0) scale(0.5) rotate(120deg) rotateY(132deg) rotateX(7deg) rotateZ(156deg);
    }
  }
  
  @-webkit-keyframes anim-24 {
    100% {
      transform: translate3d(76vw, 0vh, 0) scale(0.5) rotate(106deg) rotateY(75deg) rotateX(90deg) rotateZ(4deg);
    }
  }
  
  @keyframes anim-24 {
    100% {
      transform: translate3d(76vw, 0vh, 0) scale(0.5) rotate(106deg) rotateY(75deg) rotateX(90deg) rotateZ(4deg);
    }
  }
  
  @-webkit-keyframes anim-25 {
    100% {
      transform: translate3d(91vw, 0vh, 0) scale(0.5) rotate(93deg) rotateY(51deg) rotateX(136deg) rotateZ(143deg);
    }
  }
  
  @keyframes anim-25 {
    100% {
      transform: translate3d(91vw, 0vh, 0) scale(0.5) rotate(93deg) rotateY(51deg) rotateX(136deg) rotateZ(143deg);
    }
  }
  
  @-webkit-keyframes anim-26 {
    100% {
      transform: translate3d(88vw, 0vh, 0) scale(0.5) rotate(15deg) rotateY(153deg) rotateX(28deg) rotateZ(180deg);
    }
  }
  
  @keyframes anim-26 {
    100% {
      transform: translate3d(88vw, 0vh, 0) scale(0.5) rotate(15deg) rotateY(153deg) rotateX(28deg) rotateZ(180deg);
    }
  }
  
  @-webkit-keyframes anim-27 {
    100% {
      transform: translate3d(22vw, 0vh, 0) scale(0.5) rotate(117deg) rotateY(174deg) rotateX(20deg) rotateZ(118deg);
    }
  }
  
  @keyframes anim-27 {
    100% {
      transform: translate3d(22vw, 0vh, 0) scale(0.5) rotate(117deg) rotateY(174deg) rotateX(20deg) rotateZ(118deg);
    }
  }
  
  @-webkit-keyframes anim-28 {
    100% {
      transform: translate3d(90vw, 0vh, 0) scale(0.5) rotate(173deg) rotateY(103deg) rotateX(80deg) rotateZ(167deg);
    }
  }
  
  @keyframes anim-28 {
    100% {
      transform: translate3d(90vw, 0vh, 0) scale(0.5) rotate(173deg) rotateY(103deg) rotateX(80deg) rotateZ(167deg);
    }
  }
  
  @-webkit-keyframes anim-29 {
    100% {
      transform: translate3d(90vw, 0vh, 0) scale(0.5) rotate(180deg) rotateY(71deg) rotateX(60deg) rotateZ(117deg);
    }
  }
  
  @keyframes anim-29 {
    100% {
      transform: translate3d(90vw, 0vh, 0) scale(0.5) rotate(180deg) rotateY(71deg) rotateX(60deg) rotateZ(117deg);
    }
  }
  
  @-webkit-keyframes anim-30 {
    100% {
      transform: translate3d(12vw, 0vh, 0) scale(0.5) rotate(153deg) rotateY(120deg) rotateX(43deg) rotateZ(168deg);
    }
  }
  
  @keyframes anim-30 {
    100% {
      transform: translate3d(12vw, 0vh, 0) scale(0.5) rotate(153deg) rotateY(120deg) rotateX(43deg) rotateZ(168deg);
    }
  }
  
  @-webkit-keyframes anim-31 {
    100% {
      transform: translate3d(39vw, 0vh, 0) scale(0.5) rotate(11deg) rotateY(34deg) rotateX(151deg) rotateZ(3deg);
    }
  }
  
  @keyframes anim-31 {
    100% {
      transform: translate3d(39vw, 0vh, 0) scale(0.5) rotate(11deg) rotateY(34deg) rotateX(151deg) rotateZ(3deg);
    }
  }
  
  @-webkit-keyframes anim-32 {
    100% {
      transform: translate3d(82vw, 0vh, 0) scale(0.5) rotate(68deg) rotateY(139deg) rotateX(166deg) rotateZ(122deg);
    }
  }
  
  @keyframes anim-32 {
    100% {
      transform: translate3d(82vw, 0vh, 0) scale(0.5) rotate(68deg) rotateY(139deg) rotateX(166deg) rotateZ(122deg);
    }
  }
  
  @-webkit-keyframes anim-33 {
    100% {
      transform: translate3d(31vw, 0vh, 0) scale(0.5) rotate(152deg) rotateY(1deg) rotateX(39deg) rotateZ(30deg);
    }
  }
  
  @keyframes anim-33 {
    100% {
      transform: translate3d(31vw, 0vh, 0) scale(0.5) rotate(152deg) rotateY(1deg) rotateX(39deg) rotateZ(30deg);
    }
  }
  
  @-webkit-keyframes anim-34 {
    100% {
      transform: translate3d(14vw, 0vh, 0) scale(0.5) rotate(22deg) rotateY(166deg) rotateX(42deg) rotateZ(3deg);
    }
  }
  
  @keyframes anim-34 {
    100% {
      transform: translate3d(14vw, 0vh, 0) scale(0.5) rotate(22deg) rotateY(166deg) rotateX(42deg) rotateZ(3deg);
    }
  }
  
  @-webkit-keyframes anim-35 {
    100% {
      transform: translate3d(73vw, 0vh, 0) scale(0.5) rotate(119deg) rotateY(98deg) rotateX(96deg) rotateZ(88deg);
    }
  }
  
  @keyframes anim-35 {
    100% {
      transform: translate3d(73vw, 0vh, 0) scale(0.5) rotate(119deg) rotateY(98deg) rotateX(96deg) rotateZ(88deg);
    }
  }
  
  @-webkit-keyframes anim-36 {
    100% {
      transform: translate3d(31vw, 0vh, 0) scale(0.5) rotate(28deg) rotateY(123deg) rotateX(87deg) rotateZ(43deg);
    }
  }
  
  @keyframes anim-36 {
    100% {
      transform: translate3d(31vw, 0vh, 0) scale(0.5) rotate(28deg) rotateY(123deg) rotateX(87deg) rotateZ(43deg);
    }
  }
  
  @-webkit-keyframes anim-37 {
    100% {
      transform: translate3d(90vw, 0vh, 0) scale(0.5) rotate(132deg) rotateY(88deg) rotateX(18deg) rotateZ(68deg);
    }
  }
  
  @keyframes anim-37 {
    100% {
      transform: translate3d(90vw, 0vh, 0) scale(0.5) rotate(132deg) rotateY(88deg) rotateX(18deg) rotateZ(68deg);
    }
  }
  
  @-webkit-keyframes anim-38 {
    100% {
      transform: translate3d(12vw, 0vh, 0) scale(0.5) rotate(32deg) rotateY(95deg) rotateX(105deg) rotateZ(62deg);
    }
  }
  
  @keyframes anim-38 {
    100% {
      transform: translate3d(12vw, 0vh, 0) scale(0.5) rotate(32deg) rotateY(95deg) rotateX(105deg) rotateZ(62deg);
    }
  }
  
  @-webkit-keyframes anim-39 {
    100% {
      transform: translate3d(37vw, 0vh, 0) scale(0.5) rotate(32deg) rotateY(126deg) rotateX(145deg) rotateZ(41deg);
    }
  }
  
  @keyframes anim-39 {
    100% {
      transform: translate3d(37vw, 0vh, 0) scale(0.5) rotate(32deg) rotateY(126deg) rotateX(145deg) rotateZ(41deg);
    }
  }
  
  @-webkit-keyframes anim-40 {
    100% {
      transform: translate3d(82vw, 0vh, 0) scale(0.5) rotate(19deg) rotateY(5deg) rotateX(89deg) rotateZ(58deg);
    }
  }
  
  @keyframes anim-40 {
    100% {
      transform: translate3d(82vw, 0vh, 0) scale(0.5) rotate(19deg) rotateY(5deg) rotateX(89deg) rotateZ(58deg);
    }
  }
  
  @-webkit-keyframes anim-41 {
    100% {
      transform: translate3d(88vw, 0vh, 0) scale(0.5) rotate(114deg) rotateY(52deg) rotateX(177deg) rotateZ(76deg);
    }
  }
  
  @keyframes anim-41 {
    100% {
      transform: translate3d(88vw, 0vh, 0) scale(0.5) rotate(114deg) rotateY(52deg) rotateX(177deg) rotateZ(76deg);
    }
  }
  
  @-webkit-keyframes anim-42 {
    100% {
      transform: translate3d(4vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(106deg) rotateX(147deg) rotateZ(25deg);
    }
  }
  
  @keyframes anim-42 {
    100% {
      transform: translate3d(4vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(106deg) rotateX(147deg) rotateZ(25deg);
    }
  }
  
  @-webkit-keyframes anim-43 {
    100% {
      transform: translate3d(85vw, 0vh, 0) scale(0.5) rotate(70deg) rotateY(15deg) rotateX(11deg) rotateZ(138deg);
    }
  }
  
  @keyframes anim-43 {
    100% {
      transform: translate3d(85vw, 0vh, 0) scale(0.5) rotate(70deg) rotateY(15deg) rotateX(11deg) rotateZ(138deg);
    }
  }
  
  @-webkit-keyframes anim-44 {
    100% {
      transform: translate3d(69vw, 0vh, 0) scale(0.5) rotate(39deg) rotateY(61deg) rotateX(116deg) rotateZ(58deg);
    }
  }
  
  @keyframes anim-44 {
    100% {
      transform: translate3d(69vw, 0vh, 0) scale(0.5) rotate(39deg) rotateY(61deg) rotateX(116deg) rotateZ(58deg);
    }
  }
  
  @-webkit-keyframes anim-45 {
    100% {
      transform: translate3d(27vw, 0vh, 0) scale(0.5) rotate(57deg) rotateY(52deg) rotateX(131deg) rotateZ(23deg);
    }
  }
  
  @keyframes anim-45 {
    100% {
      transform: translate3d(27vw, 0vh, 0) scale(0.5) rotate(57deg) rotateY(52deg) rotateX(131deg) rotateZ(23deg);
    }
  }
  
  @-webkit-keyframes anim-46 {
    100% {
      transform: translate3d(3vw, 0vh, 0) scale(0.5) rotate(168deg) rotateY(152deg) rotateX(68deg) rotateZ(62deg);
    }
  }
  
  @keyframes anim-46 {
    100% {
      transform: translate3d(3vw, 0vh, 0) scale(0.5) rotate(168deg) rotateY(152deg) rotateX(68deg) rotateZ(62deg);
    }
  }
  
  @-webkit-keyframes anim-47 {
    100% {
      transform: translate3d(35vw, 0vh, 0) scale(0.5) rotate(97deg) rotateY(118deg) rotateX(170deg) rotateZ(89deg);
    }
  }
  
  @keyframes anim-47 {
    100% {
      transform: translate3d(35vw, 0vh, 0) scale(0.5) rotate(97deg) rotateY(118deg) rotateX(170deg) rotateZ(89deg);
    }
  }
  
  @-webkit-keyframes anim-48 {
    100% {
      transform: translate3d(3vw, 0vh, 0) scale(0.5) rotate(6deg) rotateY(119deg) rotateX(2deg) rotateZ(179deg);
    }
  }
  
  @keyframes anim-48 {
    100% {
      transform: translate3d(3vw, 0vh, 0) scale(0.5) rotate(6deg) rotateY(119deg) rotateX(2deg) rotateZ(179deg);
    }
  }
  
  @-webkit-keyframes anim-49 {
    100% {
      transform: translate3d(98vw, 0vh, 0) scale(0.5) rotate(46deg) rotateY(63deg) rotateX(14deg) rotateZ(125deg);
    }
  }
  
  @keyframes anim-49 {
    100% {
      transform: translate3d(98vw, 0vh, 0) scale(0.5) rotate(46deg) rotateY(63deg) rotateX(14deg) rotateZ(125deg);
    }
  }
  
  @-webkit-keyframes anim-50 {
    100% {
      transform: translate3d(4vw, 0vh, 0) scale(0.5) rotate(24deg) rotateY(102deg) rotateX(75deg) rotateZ(95deg);
    }
  }
  
  @keyframes anim-50 {
    100% {
      transform: translate3d(4vw, 0vh, 0) scale(0.5) rotate(24deg) rotateY(102deg) rotateX(75deg) rotateZ(95deg);
    }
  }
  
  @-webkit-keyframes anim-51 {
    100% {
      transform: translate3d(99vw, 0vh, 0) scale(0.5) rotate(126deg) rotateY(8deg) rotateX(153deg) rotateZ(124deg);
    }
  }
  
  @keyframes anim-51 {
    100% {
      transform: translate3d(99vw, 0vh, 0) scale(0.5) rotate(126deg) rotateY(8deg) rotateX(153deg) rotateZ(124deg);
    }
  }
  
  @-webkit-keyframes anim-52 {
    100% {
      transform: translate3d(83vw, 0vh, 0) scale(0.5) rotate(161deg) rotateY(80deg) rotateX(97deg) rotateZ(108deg);
    }
  }
  
  @keyframes anim-52 {
    100% {
      transform: translate3d(83vw, 0vh, 0) scale(0.5) rotate(161deg) rotateY(80deg) rotateX(97deg) rotateZ(108deg);
    }
  }
  
  @-webkit-keyframes anim-53 {
    100% {
      transform: translate3d(33vw, 0vh, 0) scale(0.5) rotate(180deg) rotateY(174deg) rotateX(131deg) rotateZ(50deg);
    }
  }
  
  @keyframes anim-53 {
    100% {
      transform: translate3d(33vw, 0vh, 0) scale(0.5) rotate(180deg) rotateY(174deg) rotateX(131deg) rotateZ(50deg);
    }
  }
  
  @-webkit-keyframes anim-54 {
    100% {
      transform: translate3d(5vw, 0vh, 0) scale(0.5) rotate(160deg) rotateY(176deg) rotateX(111deg) rotateZ(79deg);
    }
  }
  
  @keyframes anim-54 {
    100% {
      transform: translate3d(5vw, 0vh, 0) scale(0.5) rotate(160deg) rotateY(176deg) rotateX(111deg) rotateZ(79deg);
    }
  }
  
  @-webkit-keyframes anim-55 {
    100% {
      transform: translate3d(1vw, 0vh, 0) scale(0.5) rotate(170deg) rotateY(161deg) rotateX(106deg) rotateZ(115deg);
    }
  }
  
  @keyframes anim-55 {
    100% {
      transform: translate3d(1vw, 0vh, 0) scale(0.5) rotate(170deg) rotateY(161deg) rotateX(106deg) rotateZ(115deg);
    }
  }
  
  @-webkit-keyframes anim-56 {
    100% {
      transform: translate3d(38vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(90deg) rotateX(65deg) rotateZ(89deg);
    }
  }
  
  @keyframes anim-56 {
    100% {
      transform: translate3d(38vw, 0vh, 0) scale(0.5) rotate(8deg) rotateY(90deg) rotateX(65deg) rotateZ(89deg);
    }
  }
  
  @-webkit-keyframes anim-57 {
    100% {
      transform: translate3d(86vw, 0vh, 0) scale(0.5) rotate(111deg) rotateY(135deg) rotateX(152deg) rotateZ(15deg);
    }
  }
  
  @keyframes anim-57 {
    100% {
      transform: translate3d(86vw, 0vh, 0) scale(0.5) rotate(111deg) rotateY(135deg) rotateX(152deg) rotateZ(15deg);
    }
  }
  
  @-webkit-keyframes anim-58 {
    100% {
      transform: translate3d(94vw, 0vh, 0) scale(0.5) rotate(133deg) rotateY(22deg) rotateX(168deg) rotateZ(15deg);
    }
  }
  
  @keyframes anim-58 {
    100% {
      transform: translate3d(94vw, 0vh, 0) scale(0.5) rotate(133deg) rotateY(22deg) rotateX(168deg) rotateZ(15deg);
    }
  }
  
  @-webkit-keyframes anim-59 {
    100% {
      transform: translate3d(28vw, 0vh, 0) scale(0.5) rotate(90deg) rotateY(140deg) rotateX(148deg) rotateZ(5deg);
    }
  }
  
  @keyframes anim-59 {
    100% {
      transform: translate3d(28vw, 0vh, 0) scale(0.5) rotate(90deg) rotateY(140deg) rotateX(148deg) rotateZ(5deg);
    }
  }
  
  @-webkit-keyframes anim-60 {
    100% {
      transform: translate3d(41vw, 0vh, 0) scale(0.5) rotate(141deg) rotateY(78deg) rotateX(150deg) rotateZ(146deg);
    }
  }
  
  @keyframes anim-60 {
    100% {
      transform: translate3d(41vw, 0vh, 0) scale(0.5) rotate(141deg) rotateY(78deg) rotateX(150deg) rotateZ(146deg);
    }
  }
  