body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.links {
  color: white;
}

.fire {
  animation: burn 1.5s linear infinite alternate;
}

.bi-report {
  height: 80vh;
  width: 70%;
}

.social-media-icon {
  margin-left: 0px !important;
}

.social-media-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.social-media-divider {
  width: 28px;
  height: 28px;
  position: relative;
}

.social-media-divider:after {
  content: "";
  position: absolute;
  border-top: 1px solid grey;
  width: 50px;
  transform: rotate(115deg);
  transform-origin: 10% 400%;
}

.header-links {
  color: white !important;
  font-size: 1rem;
  font-weight: 600;
}

@keyframes burn {
  from {
    text-shadow: -0.1em 0 0.3em #fefcc9, 0.1em -0.1em 0.3em #feec85,
      -0.2em -0.2em 0.4em #ffae34, 0.2em -0.3em 0.3em #ec760c,
      -0.2em -0.4em 0.4em #cd4606, 0.1em -0.5em 0.7em #973716,
      0.1em -0.7em 0.7em #451b0e;
  }
  45% {
    text-shadow: 0.1em -0.2em 0.5em #fefcc9, 0.15em 0 0.4em #feec85,
      -0.1em -0.25em 0.5em #ffae34, 0.15em -0.45em 0.5em #ec760c,
      -0.1em -0.5em 0.6em #cd4606, 0 -0.8em 0.6em #973716,
      0.2em -1em 0.8em #451b0e;
  }
  70% {
    text-shadow: -0.1em 0 0.3em #fefcc9, 0.1em -0.1em 0.3em #feec85,
      -0.2em -0.2em 0.6em #ffae34, 0.2em -0.3em 0.4em #ec760c,
      -0.2em -0.4em 0.7em #cd4606, 0.1em -0.5em 0.7em #973716,
      0.1em -0.7em 0.9em #451b0e;
  }
  to {
    text-shadow: -0.1em -0.2em 0.6em #fefcc9, -0.15em 0 0.6em #feec85,
      0.1em -0.25em 0.6em #ffae34, -0.15em -0.45em 0.5em #ec760c,
      0.1em -0.5em 0.6em #cd4606, 0 -0.8em 0.6em #973716,
      -0.2em -1em 0.8em #451b0e;
  }
}
